@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'nunito';
}

body{
    background-color: #F8F8F8;
}

button,input{
    outline: none;
    border: none;
    background: none;
}

header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.header__title{
    font-size: 2rem;
    color: rgb(94, 94, 94);
}

.total__work{
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 10px 18px;
    background-color: #dcdbdc;
    color: #4ea6bf;
    border-radius: 8px;
    font-size: 1.2rem;
    font-weight:900;
}

.workForm{
    display: flex;
    justify-content: center;
    padding: 10px;
}

.innerForm{
    display:flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    padding: 15px 0;
    max-width: 900px;
    width: 100%;
}

.innerForm .time{
    font-weight: 700;
}

.innerForm button{
    padding: 6px 20px;
    background-color: #fd0991;
    color: #F8F8F8;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
}

.workList{
    display: flex;
    justify-content: center;
    padding: 10px;
}

.workList__Container{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 500px;
    /* justify-content: space-between; */
    max-width: 900px;
    width: 100%;
}
.workList__title{
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}
.workItem{
    display: flex;
    justify-content: space-between;
    padding: .6rem 2rem;
    background-color: #fff;
    margin-top:1rem;
}

.workItem__desc{
    max-width: 130px;
    width: 100%;
}